module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"redirect":true,"siteUrl":"https://andreslargo.com/","i18nextOptions":{"ns":["home","translation"],"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[]},
    }]
